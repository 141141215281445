// @=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@
// Alerts
// @=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@

.alert {
  padding: 12px 16px;
}


// @=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@
// Alerts Text Color
// @=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@

.alert-text-danger {
  color: $dark-danger;
}

.alert-text-warning {
  color: $dark-warning;
}

.alert-text-info {
  color: $dark-info;
}

.alert-text-success {
  color: $dark-success;
}


// @=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@
// Alerts Background Color
// @=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@
.alert-danger {
  background-color: #fcf3f4;
}

.alert-warning {
  background-color: #fefaeb;
  color: $dark-warning;
}

.alert-info {
  background-color: #f6fcfd;
  color: $dark-info;
}

.alert-success {
  background-color: #eafaf6;
  color: $dark-success;
}


// @=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@
// Alerts Border Color
// @=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@=@

.alert-border-danger {
  border: 1px solid $danger;
}

.alert-border-warning {
  border: 1px solid $warning;
}

.alert-border-info {
  border: 1px solid $info;
}

.alert-border-success {
  border: 1px solid $success;
}
