/* You can add global styles to this file, and also import other style files */
.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-top {
    width: 1px;
    object-fit: cover;
    object-position: 2% 25%;
    height: 5px;
}
.pointer {
    cursor: pointer;
}